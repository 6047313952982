import * as React from 'react'
import styled from 'styled-components'
import { colors, FONT_COLOR_SUB } from '../../../styleConstants'
import { ContentLocationWithScaled } from '../../../util/hooks/api/useContentReport'
import { TAB_INDEX, TabIndex } from '../../../pages/ContentReport/state'
import { CAPTURE_VIEW, useCaptureView } from '../../../util/hooks/useCaptureView'
import { toMs } from '../../../util/Parse'
import { RankIcon } from '../../icons/RankIcon'
import { LOWEST_RANK_TO_SHOW } from '..'

interface Props {
  readonly location: ContentLocationWithScaled
  readonly selectedTabIndex: TabIndex
}

interface MetricRowProps {
  label: string
  formattedValue: string
  rank?: number
  isSelected: boolean
}

const MetricRow: React.FC<MetricRowProps> = ({ label, formattedValue, rank, isSelected }) => (
  <Row isBold={isSelected}>
    <Label>{label}</Label>
    <Value>
      {isSelected && rank && rank !== 0 && rank <= LOWEST_RANK_TO_SHOW && (
        <IconWrapper>
          <RankIcon rank={rank} iconSize={22} />
        </IconWrapper>
      )}
      {formattedValue}
    </Value>
  </Row>
)

export function CaptureTooltipContent({
  location: {
    name,
    viewCount,
    viewCountRank,
    viewRate,
    viewRateRank,
    viewSecond,
    viewSecondRank,
    goalCount,
    goalCountRank,
    goalRate,
    goalRateRank,
    clickCount,
    clickCountRank,
    clickRate,
    clickRateRank,
    linkContentId,
  },
  selectedTabIndex,
}: Props) {
  const { captureView, isRankVisible } = useCaptureView()

  const metrics = [
    {
      label: 'ビュー：数',
      formattedValue: viewCount.toLocaleString(),
      rank: viewCountRank,
      type: CAPTURE_VIEW.VIEW_COUNT,
    },
    {
      label: 'ビュー：率',
      formattedValue: `${viewRate.toFixed(1)}%`,
      rank: viewRateRank,
      type: CAPTURE_VIEW.VIEW_RATE,
    },
    {
      label: 'ビュー：秒数',
      formattedValue: toMs(viewSecond),
      rank: viewSecondRank,
      type: CAPTURE_VIEW.VIEW_SECOND,
    },
    { type: 'divider' },
    {
      label: 'ゴール：数',
      formattedValue: goalCount.toLocaleString(),
      rank: goalCountRank,
      type: CAPTURE_VIEW.GOAL_COUNT,
    },
    {
      label: 'ゴール：率',
      formattedValue: `${goalRate.toFixed(2)}%`,
      rank: goalRateRank,
      type: CAPTURE_VIEW.GOAL_RATE,
    },
    { type: 'divider' },
    {
      label: 'クリック：数',
      formattedValue: clickCount.toLocaleString(),
      rank: clickCountRank,
      type: CAPTURE_VIEW.CLICK_COUNT,
    },
    {
      label: 'クリック：率',
      formattedValue: `${clickRate.toFixed(1)}%`,
      rank: clickRateRank,
      type: CAPTURE_VIEW.CLICK_RATE,
    },
  ]

  return (
    <Container>
      {name && (
        <>
          <ContentName>
            {selectedTabIndex === TAB_INDEX.CONTENT_GROUP && <GroupNo>{linkContentId}</GroupNo>}
            {name}
          </ContentName>
          <Divider />
        </>
      )}
      {metrics.map((metric, index) =>
        metric.type === 'divider' ? (
          <Divider key={index} />
        ) : (
          <MetricRow
            key={index}
            label={metric.label || ''}
            formattedValue={metric.formattedValue || ''}
            rank={isRankVisible ? metric.rank : undefined}
            isSelected={captureView === metric.type}
          />
        ),
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 172px;
  padding: 8px;
`

const ContentName = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${FONT_COLOR_SUB};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
`

const GroupNo = styled.span`
  padding-right: 4px;
`

const Divider = styled.div`
  height: 1px;
  background-color: ${colors.gray250};
  margin: 4px 0;
`

const Row = styled.div<{ isBold?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: ${FONT_COLOR_SUB};
  ${({ isBold }) =>
    isBold &&
    `
      font-weight: bold;
      color: ${colors.black};
    `}

  margin: 5px 0;
`

const Label = styled.span`
  flex: 1;
`

const Value = styled.span`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  font-weight: bold;
`

const IconWrapper = styled.span`
  margin-right: 4px;
  position: relative;
  top: -2px;
`
