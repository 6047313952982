import * as React from 'react'
import SelectionArea, { SelectionEvent } from '@viselect/react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { useResize } from '../../../util/hooks/useResize'
import { CaptureContentLocationBox } from './CaptureContentLocationBox'
import { OnSelectProps, TabIndex } from '../../../pages/ContentReport/state'
import { CAPTURE_AREA_ID } from '../../../util/hooks/api/usePageCaptureImageDownload'
import { ContentLocationWithScaled, PageHistory } from '../../../util/hooks/api/useContentReport'
import { getContentLocationsWithScaled } from './util'
import { useCaptureView } from '../../../util/hooks/useCaptureView'
import { SELECTION_AREA_HORIZONTAL_PADDING } from '..'

interface Props {
  readonly history: PageHistory
  readonly selectedContentIds: number[]
  readonly contentScrollTo: number | null
  readonly imageWidth: number
  readonly isScrollTop?: boolean
  readonly onResetScroll: () => void
  readonly onResetScrollTop?: () => void
  readonly onSelect: (props: OnSelectProps) => void
  readonly selectedTabIndex: TabIndex
}

const scrollContent = (y: number) => {
  const $ref = document.querySelector('.selection-container')
  $ref && $ref.scrollTo({ behavior: 'smooth', top: y })
}

const extractIds = (els: Element[]): number[] =>
  els
    .map((v) => v.getAttribute('data-key'))
    .filter(Boolean)
    .map(Number)

export function PageCaptureBox({
  history,
  selectedContentIds,
  contentScrollTo,
  isScrollTop,
  onResetScroll,
  onResetScrollTop,
  onSelect,
  imageWidth,
  selectedTabIndex,
}: Props) {
  const { isMetricVisible } = useCaptureView()
  const { windowInnerWidth } = useResize()

  const pageCaptureImageRef = React.useRef<HTMLImageElement>(null)
  const [inner, setInner] = React.useState(0)
  const ratio = inner! / history.screenshotWidth

  // 先頭へのスクロール
  React.useEffect(() => {
    if (isScrollTop) {
      scrollContent(0)
      onResetScrollTop!()
    }
  }, [isScrollTop])

  // 画像幅の変更を検知して網掛け位置を再計算する
  React.useEffect(() => {
    // 実数でセットしないと、ページが縦に長い場合に後半のハイライト位置がずれるので注意
    // see. https://github.com/uncovertruth/content-analytics/issues/1925#issuecomment-1473480469
    const width = pageCaptureImageRef.current?.getBoundingClientRect().width
    if (!width) return
    setInner(width)
  }, [imageWidth, windowInnerWidth])

  const [dragSelectedIds, setDragSelectedIds] = React.useState<number[]>([])
  const [shiftKeyPressing, setShiftKeyPressing] = React.useState<boolean>(false)
  React.useEffect(() => {
    onSelect({ ids: dragSelectedIds, shiftKeyPressing })
    setShiftKeyPressing(false)
  }, [dragSelectedIds])

  const onBeforeStart = ({ event }: SelectionEvent) => {
    if (event?.shiftKey) {
      setShiftKeyPressing(true)
    } else {
      setDragSelectedIds([])
    }
  }

  const onStop = ({ store }: SelectionEvent) => {
    setDragSelectedIds(extractIds(store.selected))
  }

  // NOTE: 表示中のキャプチャのみでスケールドパーセントを計算するため、useApiではなくここでhistory内のみで計算
  const contentLocationsWithScaled: ContentLocationWithScaled[] = getContentLocationsWithScaled(
    history.contentLocations,
  )

  return (
    <StyledSelectionArea
      className="selection-container"
      onBeforeStart={onBeforeStart}
      onStop={onStop}
      selectables=".selectable"
      features={{
        touch: false,
        range: true,
        singleTap: {
          allow: false,
          intersect: 'native',
        },
      }}
    >
      <TargetArea id={CAPTURE_AREA_ID}>
        <PageCaptureImage
          id="target-area-screenshot"
          src={history.screenshotUrl}
          className={'selectable'}
          ref={pageCaptureImageRef}
        />
        {isMetricVisible && <DarkOverlay />}
        {contentLocationsWithScaled.map((locationWithScaled) => (
          <CaptureContentLocationBox
            key={locationWithScaled.linkContentId}
            location={locationWithScaled}
            ratio={ratio}
            selected={selectedContentIds.includes(locationWithScaled.linkContentId)}
            contentScrollTo={contentScrollTo}
            onSelect={onSelect}
            onResetScroll={onResetScroll}
            scrollContent={scrollContent}
            selectedTabIndex={selectedTabIndex}
            pageCaptureAreaWidth={inner}
          />
        ))}
      </TargetArea>
    </StyledSelectionArea>
  )
}

const StyledSelectionArea = styled(SelectionArea)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 32px ${SELECTION_AREA_HORIZONTAL_PADDING}px;
  border-top: 1px solid ${colors.gray300};
  border-bottom: 1px solid ${colors.gray300};
  background-color: ${colors.gray50};
`

const TargetArea = styled.div`
  pointer-events: none;
  user-select: none;
  position: relative;
  border: 1px solid ${colors.gray300};
  margin: 0 auto;
  overflow: hidden;
`

const PageCaptureImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
`

const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.black + colors.opacity[50]};
  pointer-events: none;
`
