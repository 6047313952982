import * as React from 'react'
import styled, { css } from 'styled-components'

import { Tooltip } from '../../Tooltip'
import { OnSelectProps, TAB_INDEX, TabIndex } from '../../../pages/ContentReport/state'
import { ContentLocationWithScaled } from '../../../util/hooks/api/useContentReport'
import { CaptureTooltipContent } from './CaptureTooltipContent'
import { colors } from '../../../styleConstants'
import { CaptureGroupNumber } from './CaptureGroupNumber'
import { RankLabel } from '../RankLabel'
import { CAPTURE_AREA_ALL_PADDING, CAPTURE_AREA_LABEL_HIDE_WIDTH, LOWEST_RANK_TO_SHOW } from '..'
import { useCaptureView } from '../../../util/hooks/useCaptureView'
import { getGradationRGB, getGroupLabelBackgroundColor, getMetricItem } from './util'

interface Props {
  readonly location: ContentLocationWithScaled
  readonly ratio: number
  readonly selected: boolean
  readonly contentScrollTo: number | null
  readonly onSelect: (props: OnSelectProps) => void
  readonly scrollContent: (y: number) => void
  readonly onResetScroll: () => void
  readonly selectedTabIndex: TabIndex
  readonly pageCaptureAreaWidth: number | null
}

export function CaptureContentLocationBox({
  location,
  ratio,
  selected,
  contentScrollTo,
  onSelect,
  scrollContent,
  onResetScroll,
  selectedTabIndex,
  pageCaptureAreaWidth,
}: Props) {
  const { captureView, isFrameVisible, isMetricVisible, isRankVisible: isCaptureViewRankVisible } = useCaptureView()

  const metricItem = getMetricItem(location, captureView)
  const { rank, rankScaledPercent, formattedValue } = metricItem

  const gradationRGB = getGradationRGB(rankScaledPercent)
  const groupLabelBackgroundColor = isMetricVisible ? getGroupLabelBackgroundColor(rankScaledPercent) : undefined

  const isRankVisible = isCaptureViewRankVisible && rank <= LOWEST_RANK_TO_SHOW
  const isGroupLabelSelected = selectedTabIndex === TAB_INDEX.CONTENT_GROUP
  const isGroupLabelVisible = isFrameVisible && isGroupLabelSelected
  const isLabelAreaVisible =
    (isRankVisible || isGroupLabelVisible) &&
    !!pageCaptureAreaWidth &&
    pageCaptureAreaWidth + CAPTURE_AREA_ALL_PADDING > CAPTURE_AREA_LABEL_HIDE_WIDTH

  // 指標フォントサイズ計算
  const metricValueAdjustWidth = location.width * ratio * 0.2
  // ラベル達が表示されている場合は高さの計算に調整を加え、ラベルに指標数値が重ならないようにする
  const metricValueAdjustHeight = location.height * ratio * 1 - (isLabelAreaVisible ? 36 : 0)
  const metricValueFontSize = Math.min(metricValueAdjustWidth, metricValueAdjustHeight)
  const minLimitFontSize = isLabelAreaVisible
    ? 8 // ラベル達が表示されている場合はスペースが無いので8px以上のみ表示する
    : 5 // ラベル達が非表示になったらスペースに余裕ができるので制限を緩め、5pxまで表示する
  const isMetricValueVisible = isMetricVisible && minLimitFontSize <= metricValueFontSize

  React.useEffect(() => {
    if (contentScrollTo !== null && contentScrollTo === location.linkContentId) {
      scrollContent(location.topLeftY * ratio)
      onResetScroll()
    }
  }, [contentScrollTo])

  const onClickContent = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onSelect({ ids: [location.linkContentId], shiftKeyPressing: event.shiftKey, selected })
  }

  return (
    <Container
      id={`${location.linkContentId}-box`}
      key={`${location.linkContentId}-key`}
      data-key={location.linkContentId}
      className={'selectable'}
      ratio={ratio}
      width={location.width}
      height={location.height}
      x={location.topLeftX}
      y={location.topLeftY}
      selected={selected}
      onClick={onClickContent}
      data-testid={`capture-frame-wrapper-${location.linkContentId}`}
    >
      <Tooltip content={CaptureTooltipContent({ location, selectedTabIndex })} borderColor="none" followCursor={true}>
        <TooltipItem
          id={`${location.linkContentId}`}
          selected={selected}
          isFrameVisible={isFrameVisible}
          isMetricVisible={isMetricVisible}
          gradationRGB={gradationRGB}
          data-testid={`capture-frame-${location.linkContentId}`}
        >
          {isLabelAreaVisible && (
            <LabelArea selectedTabIndex={selectedTabIndex}>
              {isGroupLabelVisible && (
                <CaptureGroupNumber location={location} backgroundColor={groupLabelBackgroundColor} />
              )}
              {isRankVisible && (
                <RankLabel rank={rank} isLarge={isGroupLabelSelected} backgroundColor={colors.yellow} />
              )}
            </LabelArea>
          )}
          {isMetricValueVisible && <MetricValue fontSize={metricValueFontSize}>{formattedValue}</MetricValue>}
        </TooltipItem>
      </Tooltip>
    </Container>
  )
}

type ContainerProps = {
  ratio: number
  width: number
  height: number
  x: number
  y: number
  selected: boolean
}
const Container = styled.div.attrs<ContainerProps>(({ width, height, x, y, ratio }) => ({
  // NOTE: styled-componentsの「Over 200 classes」警告を解消しパフォーマンスを改善させるため、動的な値はattrsのstyleで定義した
  // see. https://github.com/uncovertruth/content-analytics/pull/2728
  style: {
    width: `${width * ratio}px`,
    height: `${height * ratio}px`,
    top: `${y * ratio}px`,
    left: `${x * ratio}px`,
    right: `${(x + width) * ratio}px`,
    bottom: `${(y + height) * ratio}px`,
  },
}))<ContainerProps>`
  position: absolute;
  cursor: pointer;
  z-index: ${({ selected }) => (selected ? 21 : 20)};
  pointer-events: auto;
`

type TooltipItemProps = {
  selected: boolean
  isFrameVisible: boolean
  isMetricVisible: boolean
  gradationRGB: string
}

const SELECTED_DASH_BORDER_WIDTH = 2
const TooltipItem = styled.div<TooltipItemProps>`
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;

  background-color: ${({ selected, isFrameVisible, isMetricVisible, gradationRGB }) => {
    if (isMetricVisible) return `rgba(${gradationRGB}, 0.3)`
    if (selected && !isFrameVisible) return `${colors.red}${colors.opacity[20]}` // 枠なし時は選択範囲が分かりづらいため背景色追加した
    return 'transparent'
  }};

  border-style: ${({ isFrameVisible }) => {
    if (isFrameVisible) return 'solid'
    return 'none'
  }};

  border-color: ${({ isMetricVisible, gradationRGB }) => {
    if (isMetricVisible) return `rgba(${gradationRGB}, 0.7)`
    return colors.contentOrange.orange5
  }};

  border-width: ${({ isMetricVisible, selected }) => {
    if (selected) return `${SELECTED_DASH_BORDER_WIDTH}px`
    if (isMetricVisible) return '2px'
    return '1px'
  }};

  /* 選択時の破線アニメーション */

  ${({ selected, isFrameVisible }) =>
    selected &&
    css`
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: ${selected && !isFrameVisible
          ? 0 // 枠なし時は選択範囲が分かりづらいため、オレンジ背景の上にborderが出るように内側に調整した
          : -SELECTED_DASH_BORDER_WIDTH}px;
        left: ${selected && !isFrameVisible ? 0 : -SELECTED_DASH_BORDER_WIDTH}px;
        right: ${selected && !isFrameVisible ? 0 : -SELECTED_DASH_BORDER_WIDTH}px;
        bottom: ${selected && !isFrameVisible ? 0 : -SELECTED_DASH_BORDER_WIDTH}px;

        background-image: repeating-linear-gradient(0deg, #fff, #fff 4px, transparent 4px, transparent 10px, #fff 10px),
          repeating-linear-gradient(90deg, #fff, #fff 4px, transparent 4px, transparent 10px, #fff 10px),
          repeating-linear-gradient(180deg, #fff, #fff 4px, transparent 4px, transparent 10px, #fff 10px),
          repeating-linear-gradient(270deg, #fff, #fff 4px, transparent 4px, transparent 10px, #fff 10px);
        background-size: ${SELECTED_DASH_BORDER_WIDTH}px calc(100% + 10px),
          calc(100% + 10px) ${SELECTED_DASH_BORDER_WIDTH}px, ${SELECTED_DASH_BORDER_WIDTH}px calc(100% + 10px),
          calc(100% + 10px) ${SELECTED_DASH_BORDER_WIDTH}px;
        background-position: 0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;
        animation: borderAnimation 1s infinite linear;
      }
      @keyframes borderAnimation {
        from {
          background-position: 0 0, -10px 0, 100% -10px, 0 100%;
        }
        to {
          background-position: 0 -10px, 0 0, 100% 0, -10px 100%;
        }
      }
    `}
`

const LabelArea = styled.div<{ selectedTabIndex: TabIndex }>`
  display: flex;
  height: 100%;
  max-height: ${({ selectedTabIndex }) => (selectedTabIndex === TAB_INDEX.CONTENT_GROUP ? '30px' : '20px')};
  width: 100%;
`

const MetricValue = styled.div<{ fontSize: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  padding: 2px 4px;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: bold;
  color: ${colors.white};
  text-shadow: -1px -1px 0 ${colors.black}, 1px -1px 0 ${colors.black}, -1px 1px 0 ${colors.black},
    1px 1px 0 ${colors.black};
`
